import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/views/MainHomePages/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    // route level code-splitting
    // this generates a separate chunk (MainHomePages.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MainHomePages" */ "../views/MainHomePages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Banks",
        name: "Banks",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/Banks.vue"
          ),
      },
      {
        path: "/Pricing",
        name: "Pricing",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/Pricing.vue"
          ),
      },
      {
        path: "/CreditWorthiness",
        name: "CreditWorthiness",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/CreditWorthiness.vue"
          ),
      },
      {
        path: "/Purposes",
        name: "Purposes",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/Purposes.vue"
          ),
      },
      {
        path: "/Properties",
        name: "Properties",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/Properties.vue"
          ),
      },
      {
        path: "/Covenants",
        name: "Covenants",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/Covenants.vue"
          ),
      },
      {
        path: "/PayOffMethods",
        name: "PayOffMethods",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/PayOffMethods.vue"
          ),
      },
      {
        path: "/PropertyClasses",
        name: "PropertyClasses",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/PropertyClasses.vue"
          ),
      },
      {
        path: "/Floors",
        name: "Floors",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/Floors.vue"
          ),
      },
      {
        path: "/RevenueSize",
        name: "RevenueSize",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/RevenueSize.vue"
          ),
      },

      {
        path: "/AdminUsers",
        name: "AdminUsers",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/AdminUsers.vue"
          ),
      },
      {
        path: "/SubFacility",
        name: "SubFacility",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/SubFacility.vue"
          ),
      },
      {
        path: "/AllDeals",
        name: "AllDeals",
        component: () =>
          import(
            /* webpackChunkName: "ChildHomePages" */ "../views/ChildHomePages/AllDeals.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;


// {
//   "MenuName": "Flooring",
//   "MenuRoute": "Floors",
//   "has_child_routes": false,
//   "MenuIcon": "mdi-currency-usd"
// }
// ,