import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0354e0",
        whiteColorVariant1: "#a6c6ff",
        greenColorVariant1: "#cefcce",
        blueColorVariant1: "#ccd7eb",
        blueColorVariant2: "#003a9f",
        blueColorVariant3: "#f0f8ff",
        blueColorVariant4: "0354e0 ",
        redColorVariant1: "#FFE5E5",
      },
    },
  },
});
