<template>
  <div class="background">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-row no-gutters>
      <v-col cols="12" md="7" sm="12" xs="12">
        <div class="bg-img">
          <v-card flat tile>
            <div class="leftSide">
              <v-container fluid fill-height color="grey lighten-3">
                <v-layout align-center justify-center>
                  <v-flex>
                    <div
                      class="text-center FontSize65px black--text lineUp"
                    ></div>
                    <div
                      class="text-center FontSize45px black--text lineUp2"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="5" sm="12" xs="12">
        <v-container fluid fill-height color="grey lighten-3">
          <v-layout align-center justify-center>
            <v-card class="elevation-0" color="transparent" max-width="500px">
              <v-card-text>
                <v-card
                  class="elevation-0 ma-6"
                  align="left"
                  color="transparent"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="10"
                      align="left"
                      class="pb-0 py-0"
                      v-if="loginStepper != 5"
                    >
                      <div
                        class="GradientText font-weight-bold pt-2 white--text lineHeightVariant1"
                      >
                        DealData -
                        {{ GetCurrentHostName }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="10"
                      align="left"
                      v-if="loginStepper != 5"
                    >
                      <v-img contain src="@/assets/dth.png"></v-img>
                    </v-col>
                  </v-row>
                  <v-window v-model="loginStepper">
                    <v-window-item :value="1">
                      <v-card-text class="pa-0">
                        <v-form ref="form" @submit.prevent lazy-validation>
                          <div class="font-weight-bold my-3 white--text">
                            Email ID
                          </div>
                          <v-text-field
                            dense
                            outlined
                            class="borderRadiusVariant1"
                            background-color="white"
                            @keydown.space="preventLeadingSpace"
                            @keydown.enter="ValidateSignUpForm()"
                            :rules="[(v) => !!v || 'Email ID is required']"
                            v-model="Login.EmailID"
                          ></v-text-field>
                          <div class="font-weight-bold white--text mb-3">
                            Password
                          </div>
                          <v-text-field
                            dense
                            outlined
                            class="borderRadiusVariant1"
                            background-color="white"
                            :type="isPwdLogin ? 'text' : 'password'"
                            @keydown.space="preventLeadingSpace"
                            @keydown.enter="ValidateSignUpForm()"
                            required
                            :rules="[(v) => !!v || 'Password is required']"
                            v-model="Login.Password"
                            :append-icon="
                              isPwdLogin ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="isPwdLogin = !isPwdLogin"
                          >
                          </v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="px-0">
                        <v-btn
                          :loading="loading"
                          tile
                          class="borderRadiusVariant2 text-capitalize px-6"
                          color="primary"
                          @click="ValidateSignUpForm()"
                          >Sign In</v-btn
                        >
                      </v-card-actions>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-card-text class="pa-0">
                        <v-form ref="formCP" @submit.prevent lazy-validation>
                          <div class="font-weight-bold my-3 white--text">
                            Enter New password *
                          </div>
                          <v-text-field
                            outlined
                            class="borderRadiusVariant1 mr-4"
                            background-color="white"
                            dense
                            :type="isPwdNew ? 'text' : 'password'"
                            @click:append="isPwdNew = !isPwdNew"
                            required
                            :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                            prepend-inner-icon="mdi-lock"
                            :rules="[(v) => !!v || 'Password is required']"
                            v-model="Password.NewPassword"
                          ></v-text-field>
                          <div class="font-weight-bold my-3 white--text">
                            Confirm New password *
                          </div>
                          <v-text-field
                            outlined
                            class="borderRadiusVariant1 mr-4"
                            background-color="white"
                            dense
                            :type="isPwdConfirm ? 'text' : 'password'"
                            @click:append="isPwdConfirm = !isPwdConfirm"
                            required
                            :append-icon="
                              isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            prepend-inner-icon="mdi-lock"
                            :rules="[(v) => !!v || 'Password is required']"
                            v-model="Password.ConfirmPassword"
                          ></v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="pt-0 pb-8">
                        <v-btn
                          block
                          :loading="loading"
                          tile
                          class="pa-0 ma-0"
                          color="primary"
                          @click="ValidateConfirmPasswordForm()"
                          >Login</v-btn
                        >
                      </v-card-actions>
                    </v-window-item>
                  </v-window>
                </v-card>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    loginStepper: 1,

    loading: false,
    isPwdLogin: false,

    SnackBarComponent: {},
    Login: {
      EmailID: "",
      Password: "",
    },
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    isPwdNew: false,
    isPwdConfirm: false,
  }),
  watch: {
    "Login.EmailID"(val) {
      this.Login.EmailID = val.toLowerCase();
    },
  },
  computed: {
    GetCurrentHostName() {
      return window.location.hostname === "devbms.commdealdata.com"
        ? "DEV BMS"
        : window.location.hostname === "testbms.commdealdata.com"
        ? "TEST BMS"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "UAT BMS"
        : "BMS";
    },
    currentTitle() {
      switch (this.loginStepper) {
        case 1:
          return "Welcome to DealData - BMS";
        case 2:
          return "Change Temporary Password";
        default:
          return "Create a DealData account to submit and analyze deals";
      }
    },
  },
  methods: {
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(
          this.user,
          this.Password.ConfirmPassword
        )
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        this.user = await Auth.signIn(this.Login.EmailID, this.Login.Password);
        if (this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.loginStepper = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          setTimeout(() => {
            this.ActivateMethod();
            this.loading = false;
          }, 500);
        }
      } catch (error) {
        console.log("Error", error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },

    ActivateMethod() {
      setTimeout(() => this.$router.push("/LandingPage"), 100);
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
  },
};
</script>

<style>
.background {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(132deg, #001943, #023a9c, #0019436e);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
}
.background .bg-img {
  top: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 999;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 350px 0;
}
.FontSize65px {
  font-size: 65px !important;
}
.FontSize45px {
  font-size: 45px !important;
}
.FontSize35px {
  font-size: 35px !important;
}
.GradientText {
  font-size: 35px !important;
  font-weight: 600 !important;
  background-image: linear-gradient(to right, #0354e0, #81a9ef) !important;
  background-size: 100%;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.lineUp {
  animation: 3s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.lineUp2 {
  animation: 4s lineup2 ease-out !important;
}
@keyframes lineup2 {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
