import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { Amplify } from "aws-amplify";
Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === "devbms.commdealdata.com" 
        ? "us-east-1_mUWvuwT3H"
        : window.location.hostname === "testbms.commdealdata.com"  || window.location.hostname === 'localhost'
        ? "us-east-1_JJ6qxHMKb"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "us-east-1_wqNM6Fkrn"
        : "us-east-1_porUfhUp6",
    region: "us-east-1",
    userPoolWebClientId:
      window.location.hostname === "devbms.commdealdata.com" 
        ? "7oaq7f2ashs9ae43rmgkkavqn5"
        : window.location.hostname === "testbms.commdealdata.com"  || window.location.hostname === 'localhost'
        ? "6qoonik7k4tutvi5rm46mqk9po"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "2lek4bre9j4n02vdqi9dvr97gh"
        : "6v8064i7novl07ub4h44j0pdg3",
  },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      window.location.hostname === "devbms.commdealdata.com" 
        ? "https://trnffuhbgjgjvj37bqkj7t5mc4.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "testbms.commdealdata.com" || window.location.hostname === 'localhost'
        ? "https://fxipxjy5dbfxxioy4o2oufah3y.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "uatbms.commdealdata.com"
        ? "https://pufkzcx67vbl3adapj2g7zz26m.appsync-api.us-east-1.amazonaws.com/graphql"
        : "https://7og6seqawrez3fsc7r7sa7pory.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
