<template>
  <v-app :style="{ background: customBackgroundColor }">
    <router-view />
    <!-- For Commit test-->
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      customBackgroundColor: "#f0f8ff",
    };
  },
};
</script>
<style>
.fontSize12px {
  font-size: 0.75rem !important;
}
.fontSize14px {
  font-size: 0.875rem !important;
}
.fontSize15px {
  font-size: 0.9375rem !important;
}
.fontSize16px {
  font-size: 1rem !important;
}
.fontSize18px {
  font-size: 1.125rem !important;
}
.fontSize20px {
  font-size: 1.25rem !important;
}
.fontSize25px {
  font-size: 1.5625rem !important;
}
.fontSize30px {
  font-size: 1.875rem !important;
}
.fontSize40px {
  font-size: 2.3rem !important;
}

.fontWeightVariant1 {
  font-weight: 500 !important;
}

.fontWeightVariant2 {
  font-weight: 900 !important;
}
.fontWeightVariant3 {
  font-weight: 700 !important;
}
.lineHeightVariant1 {
  line-height: 1.875rem !important;
}

.borderRadiusVariant1 {
  border-radius: 0.4375rem !important;
}

.borderRadiusVariant2 {
  border-radius: 1.25rem !important;
}
.dottedBorder {
  border: 0.0625rem dashed black !important;
}
.borderVariant1 {
  border: 0.0625rem dashed rgb(0, 0, 0) !important;
}
.greyColorVariant1 {
  background-color: #d5d1d1 !important;
}
.cursorPointer {
  cursor: pointer !important;
}
.maxWidthVariant1 {
  max-width: 11.25rem !important;
}
.maxWidthVariant2 {
  max-width: 13.125rem !important;
}
.maxWidthVariant3 {
  max-width: 18.75rem !important;
}
.maxWidthVariant4 {
  max-width: 50rem !important;
}
.scroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 0.75rem !important;
  height: 0.75rem !important;
  background-color: #ffffff;
  color: red;
}
.scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #0f99da !important;
  border: solid 0.1875rem #e6e6e6 !important;
  border-radius: 0.4375rem !important;
}

.custom-scrollbar {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  scrollbar-color: #003a9f #f5f5f5 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #003a9f !important;
  border-radius: 0.25rem !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #003a9f !important;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}

.btn-bottom-center {
  position: absolute !important;
  bottom: 6.25rem;
  left: 50%;
  transform: translateX(-50%);
}
.GreenBackground {
  background-color: #4caf4f49 !important;
  font-weight: bolder !important;
}
.RedBackground {
  background-color: #f4433649 !important;
  font-weight: bolder !important;
}
.primaryBackgrund {
  background-color: #0354e049 !important ;
  font-weight: bolder !important;
}
.RoundedBorderRadius {
  border-radius: 1.25rem !important;
}
.buttonClassVarient1 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
  width: 13.75rem !important;
  height: 3.5rem !important;
  padding: 2rem, 2rem, 2rem, 2rem !important;
  border-radius: 2.3rem !important;
  color:#ffffff !important;
  background-color: #0354e0 !important;
  gap: 0.625rem !important;
  cursor: pointer !important;
}
.buttonClassVarient2 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
  width: 12.375rem !important;
  height: 3.5rem !important;
  padding: 2rem, 2rem, 2rem, 2rem !important;
  border-radius: 2.3rem !important;
  color:#ffffff !important;
  background-color: #0354e0 !important;
  gap: 0.625rem !important;
  cursor: pointer !important;
}
.buttonClassVarient3 {
  width: 7.8125rem !important;
  height: 2.1875rem !important;
  padding: 0.5rem, 1.5rem, 0.5rem, 1.5rem !important;
  border-radius: 0.75rem !important;
  gap: 1.25rem !important;
}
.textVarient1 {
  font-size: 2rem !important;
  font-weight: 600 !important;
  line-height: 2.420625rem !important;
  letter-spacing: -3% !important;
  color: #000000 !important;
}
.LabelClassVariant6 {
  color: var(--primary-black, #000) !important;
  font-family: Inter, sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06em !important;
}
.LabelClassVariant8 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}
.LabelClassVariant9 {
  font-family: Inter, sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}

.NavigationEllipseDesign {
  top: 660.0625rem !important;
  gap: 0.43625rem !important;
  opacity: 25% !important;
  margin-top: -3.75rem !important;
}
.tableHeaderClass table th{
font-size: 0.9375rem !important;
}
.cardContentVarient1 {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  line-height: 1.5125rem !important;
  letter-spacing: -3% !important;
  width: 53.3125rem !important;
  height: 5rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem !important;
  justify-content: space-between !important;
  padding: 1.25rem !important;
  background-color: #f1f8ff !important;
}
</style>